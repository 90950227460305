let timeoutRefresh = null;
let alertTimeBeforeExpire = 60;
let intervalRefresh = 10;
let workerLocation = self.location;

function refreshToken(message){
  clearTimeout(timeoutRefresh);
  let scopeMessage = message;
  let timestampExpire = (scopeMessage.payload.token_data.exp*1000);
  let currentTime = (new Date()).getTime()
  let liveSeconds = (timestampExpire-currentTime)/1000;
  if(liveSeconds>0){
    let timeoutTime = (liveSeconds>alertTimeBeforeExpire && liveSeconds<(alertTimeBeforeExpire+10))?1:intervalRefresh;
    timeoutRefresh = setTimeout(()=>{
      if(liveSeconds<alertTimeBeforeExpire){
        self.postMessage(scopeMessage);
      }else{
        /* Hacer una nueva verificacion, para validar expiracion */
        refreshToken(scopeMessage);
      }
    },timeoutTime*1000);
  }else{
    self.postMessage(scopeMessage);
  }
}

self.addEventListener('install', function(event) {
  event.waitUntil(self.skipWaiting()); // Activate worker immediately
});

self.addEventListener('activate', function(event) {
  event.waitUntil(self.clients.claim()); // Become available to all pages
});

self.addEventListener('message', (event) => {
  /* Procesar unicamente eventos tipo ACTION */
  if(event.data.type==="ACTION"){
    // Obtener el tipo de evento ejecutado
    let actionData = JSON.parse(event.data.action);
    switch(actionData.action.type){
      case 'SET_CUSTOM_REFRESH_TOKEN':
        refreshToken(actionData.action);
        break;
    }
  }
});